$browser-context: 14; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@media screen and (max-width: 1670px) {
  header .container {
    max-width: 1237px;
  }
  .container {
    max-width: 1228px;
  }
  .extra-wrap {
    padding: 0 90px;
  }
  .main-btn {
    font-size: 15px;
    border-radius: 40px;
  }
  header {
    padding: 15px 0;
  }
  .main-logo {
    max-width: 58px;
  }
  .header-data {
    width: calc(100% - 58px);
  }
  .main-logo a {
    display: block;
    margin-top: -13px;
  }
  .main-menu {
    padding: 0 0 0 23px;
  }
  .main-menu ol li {
    padding: 0 10px;
  }
  .main-menu ol li a {
    padding: 5px;
    font-size: 13px;
  }
  header .tel-platform {
    padding: 0 8px 0 0;
  }
  .change-lang {
    padding: 5px 8px;
    font-size: 12px;
  }
  .main-tel a {
    padding: 4px 0;
    font-size: 12px;
  }
  .main-content {
    padding: 127px 0 61px 0;
  }
  .main-title h1,
  .our-goods-title h2,
  .experience-title h2,
  .more-goods-title h2 {
    font-size: 35px;
    line-height: 46px;
  }
  .main-title h1:before,
  .our-goods-title h2:before,
  .experience-title h2:before {
    width: 43px;
    //top: 19px;
    top: 23px;
    left: -90px;
  }
  .main-title p {
    padding: 0 0 12px 0;
    font-size: 21px;
  }
  .main-title h1 span {
    padding-top: 4px;
    font-size: 20px;
  }
  .main-advantages {
    padding: 30px 0 31px 0;
  }
  .main-advantages ol {
    max-width: 470px;
  }
  .main-advantages ol li:before {
    width: 48px;
    height: 55px;
    top: 3px;
    left: -16px;
  }
  .main-advantages ol li {
    padding: 21px 20px 21px 32px;
    font-size: 12px;
    line-height: 18px;
  }
  .main-content-btn p {
    padding: 0 0 22px 0;
    font-size: 21px;
  }
  .main-content-btn .main-btn,
  .main-modal-title .main-btn,
  .not-found-btn .main-btn {
    max-width: 271px;
    min-height: 58px;
  }
  .our-goods-wrap {
    padding: 64px 0 94px 0;
  }
  .our-goods-title p,
  .experience-title p {
    padding: 0 0 7px 0;
    font-size: 20px;
  }
  .our-goods-text {
    padding: 30px 0 15px 0;
    max-width: 318px;
  }
  .our-goods-text p,
  .experience-text p {
    padding: 9px 0 0 0;
    font-size: 19px;
    line-height: 30px;
  }
  .our-goods-text .main-btn {
    max-width: 271px;
    min-height: 59px;
    margin: 41px 0 0 0;
  }
  .goods-advantage-item span {
    width: 45px;
    height: 57px;
  }
  .goods-advantage-item {
    padding: 16px 0 49px 55px;
  }
  .goods-advantage-item h4 {
    font-size: 19px;
    line-height: 21px;
  }
  .goods-advantage-item p {
    padding: 12px 0 0 0;
    font-size: 16px;
    line-height: 25px;
  }
  .goods-section-title h3 {
    font-size: 35px;
  }
  .goods-section-title p {
    padding: 16px 0 0 0;
    max-width: 472px;
    font-size: 21px;
    line-height: 30px;
  }
  .goods-card-container {
    padding: 48px 0;
  }
  .goods-card {
    padding: 35px 0;
  }
  .goods-img {
    height: 501px;
  }
  .goods-item p {
    font-size: 20px;
  }
  .goods-item h4 {
    font-size: 31px;
    line-height: 46px;
  }
  .goods-description {
    padding: 16px 0 0 0;
  }
  .goods-description p {
    font-size: 14px;
    line-height: 22px;
  }
  .goods-description ol {
    padding: 19px 0 16px 0;
  }
  .goods-description ol li:before {
    width: 48px;
    height: 55px;
  }
  .goods-description ol li {
    padding: 12px 0 12px 34px;
    font-size: 14px;
    line-height: normal;
  }
  .goods-notice p:before {
    width: 29px;
    height: 29px;
    //top: 4px;
  }
  .goods-notice {
    min-height: 29px;
  }
  .goods-notice p {
    padding: 0 10px 0 45px;
    font-size: 13px;
    line-height: 16px;
  }
  .goods-notice {
    width: calc(100% - 191px);
  }
  .goods-order .main-btn {
    max-width: 186px;
    min-height: 42px;
    font-size: 10px;
  }
  .offer-wrap {
    padding: 71px 0;
  }
  .offer-title p {
    font-size: 20px;
  }
  .offer-title h3 {
    font-size: 31px;
    line-height: 41px;
  }
  .offer-text {
    padding: 34px 0 0 0;
  }
  .offer-text p {
    font-size: 16px;
  }
  .offer-text ol li:before {
    width: 48px;
    height: 55px;
    top: -10px;
  }
  .offer-text ol li {
    padding: 14px 0 14px 36px;
    font-size: 13px;
  }
  .offer-discount p {
    font-size: 21px;
  }
  .offer-discount p b {
    font-size: 27px;
  }
  .quiz-page {
    padding-top: 90px;
  }
  .quiz-wrap {
    padding: 77px 0 124px 0;
  }
  .quiz-title {
    padding: 0 0 61px 0;
  }
  .quiz-title p {
    padding: 0 0 10px 0;
    font-size: 20px;
  }
  .quiz-title h2 {
    font-size: 33px;
    line-height: 44px;
  }
  .quiz-container {
    padding: 27px;
  }
  .quiz-question {
    padding: 17px;
  }
  .question-text {
    padding: 0 0 32px 0;
  }
  .question-text p {
    padding: 5px 0 6px 0;
    font-size: 11px;
  }
  .question-text h3 {
    font-size: 26px;
  }
  .place-img {
    height: 159px;
  }
  .place-option label span,
  .feature-option label span {
    font-size: 19px;
  }
  .place-option label {
    margin: 20px 0 0 0;
    padding: 6px 47px;
  }
  .place-option label span:after {
    left: 47px;
    right: 47px;
  }
  .place-option label span:before,
  .feature-option label span:before {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .feature-option label {
    padding: 4px 0 4px 44px;
    margin: 11px 0;
  }
  .use-option label span:before {
    left: 14px;
  }
  .use-option label span {
    padding: 8px 15px 6px 49px;
    font-size: 19px;
    line-height: 21px;
  }
  .continue-quiz {
    padding: 30px 0 15px 0;
  }
  .continue-quiz .main-btn {
    max-width: 230px;
    min-height: 50px;
  }
  .our-works {
    padding: 76px 0;
  }
  .our-works-title p {
    padding: 0 0 10px 0;
    font-size: 20px;
  }
  .our-works-title h2 {
    font-size: 33px;
  }
  .our-works-wrap {
    padding: 42px 0;
  }
  .our-works-info {
    padding: 39px 34px;
    height: 262px;
  }
  .our-works-main h4 {
    font-size: 15px;
    line-height: 25px;
  }
  .our-works-main p {
    padding: 11px 7px 5px 0;
    font-size: 13px;
    line-height: 20px;
  }
  .our-works-about a {
    font-size: 11px;
  }
  .more-our-works p {
    font-size: 19px;
  }
  .experience {
    padding: 80px 0 89px 0;
  }
  .experience-item {
    padding: 0 0 13px 0;
    margin: 0 0 38px 0;
    &:after {
      right: 25px;
      left: 25px;
    }
  }
  .experience-advantages-icon span {
    width: 91px;
    height: 90px;
  }
  .experience-advantages-icon {
    width: 91px;
  }
  .experience-advantages-text {
    width: calc(100% - 91px);
    padding: 14px 0 0 27px;
  }
  .experience-item-wrap {
    padding: 22px 33px;
    min-height: 208px;
  }
  .experience-advantages-text h3 {
    font-size: 15px;
    line-height: 25px;
  }
  .experience-advantages-text h4 {
    font-size: 19px;
    line-height: 28px;
  }
  .experience-advantages-icon p {
    padding: 0 0 0 0;
    font-size: 15px;
  }
  .experience-advantages-text p {
    padding: 14px 0 0 0;
    font-size: 15px;
    line-height: 25px;
  }
  .more-goods {
    padding: 71px 0;
  }
  .more-goods-items {
    padding: 40px 0 0 0;
  }
  .more-goods-bg {
    height: 154px;
    padding: 35px;
  }
  .more-goods-bg h3 {
    padding: 0 0 10px 0;
    font-size: 30px;
  }
  .more-goods-bg a {
    font-size: 12px;
  }
  footer {
    padding: 42px 0;
  }
  .footer-logo a {
    width: 149px;
  }
  .footer-logo p {
    width: calc(100% - 149px);
    padding: 0 0 0 34px;
    font-size: 12px;
    line-height: 17px;
  }
  .join-us {
    min-width: 32%;
  }
  .join-us p {
    font-size: 12px;
  }
  .join-us p span {
    padding: 0 13px 0 0;
  }
  .join-us p a {
    padding: 0 6px;
  }
  .footer-copyright {
    padding: 46px 0 0 0;
  }
  .footer-rights p {
    font-size: 12px;
  }
  .made-by p {
    font-size: 12px;
  }
  .made-by p a {
    padding: 2px 0 0 0;
    width: 64px;
  }

  .project-title h2 {
    font-size: 34px;
  }
  .project-title p {
    padding: 15px 0 0 0;
    font-size: 20px;
  }
  .project-title-wrap {
    min-height: 269px;
  }
  .share-project p {
    font-size: 14px;
  }
  .project-data {
    padding: 72px 0;
  }
  .project-time h3 {
    font-size: 35px;
  }
  .project-time h3:before {
    width: 44px;
    //top: 22px;
    top: 20px;
    left: -90px;
  }
  .made-work ol li span {
    padding: 0 10px 0 41px;
  }
  .made-work ol li {
    padding: 12px 0;
    font-size: 15px;
    line-height: 24px;
  }
  .made-work ol li span:before {
    font-size: 26px;
  }
  .project-work {
    padding: 0 0 25px 0;
  }
  .project-section-title p {
    padding: 0 0 12px 0;
    font-size: 19px;
  }
  .project-section-title h2 {
    font-size: 33px;
    line-height: 36px;
  }
  .project-review-wrap {
    padding: 12px 0;
  }
  .review-item-bg {
    height: 330px;
  }
  .reviews-video {
    padding: 36px 0 0 0;
  }
  .reviews-video.client-video .review-item-bg {
    height: 377px;
  }
  .project-contact-bg {
    padding: 78px 0;
  }
  .project-contact-title h3 {
    padding: 0 0 13px 0;
    font-size: 26px;
  }
  .project-contact-title p {
    font-size: 18px;
  }

  .form-fields {
    padding: 31px 0;
  }
  .form-fields label input {
    padding: 16px 28px 16px 46px;
    font-size: 16px;

  }
  .form-fields label i {
    font-size: 12px;
    top: 22px;
    left: 26px;
  }
  .form-fields label {
    max-width: 285px;
  }
  .form-fields .main-btn {
    max-width: 231px;
    min-height: 51px;
  }
  .form-agreement label span {
    font-size: 14px;
  }
  .form-agreement label {
    padding: 2px 0 2px 30px;
  }
}

@media screen and (max-width: 1450px) {
  header .container,
  .container {
    max-width: 1058px;
  }
  .extra-wrap {
    padding: 0 78px;
  }
  .main-logo {
    max-width: 52px;
  }
  .header-data {
    width: calc(100% - 52px);
  }
  .main-menu {
    padding: 0 0 0 20px;
  }
  .main-menu ol li {
    padding: 0 3px;
  }
  header .site-tel {
    padding: 7px 16px 7px 7px;
  }
  .main-content {
    padding: 110px 0 50px 0;
  }
  .main-advantages {
    padding: 29px 0 28px 0;
  }
  .main-advantages ol {
    max-width: 415px;
  }
  .main-advantages ol li {
    padding: 18px 20px 18px 29px;
    line-height: normal;
  }
  .main-advantages ol li:before {
    width: 45px;
    height: 43px;
  }

  .main-title p {
    padding: 0 0 8px 0;
    font-size: 18px;
  }
  .main-title h1,
  .our-goods-title h2,
  .experience-title h2,
  .more-goods-title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .main-title h1 span {
    font-size: 17px;
  }
  .main-title h1:before,
  .our-goods-title h2:before,
  .experience-title h2:before {
    width: 37px;
    //top: 15px;
    top: 20px;
    left: -78px;
  }
  .main-content-btn p {
    padding: 0 0 20px 0;
    font-size: 18px;
  }
  .main-content-btn .main-btn,
  .main-modal-title .main-btn,
  .not-found-btn .main-btn {
    max-width: 234px;
    min-height: 51px;
  }
  .main-btn {
    font-size: 13px;
    border-radius: 30px;
  }

  .our-goods-wrap {
    padding: 53px 0 83px 0;
  }
  .our-goods-title p,
  .experience-title p {
    font-size: 17px;
  }

  .our-goods-text p, .experience-text p {
    padding: 9px 0 0 0;
    font-size: 16px;
    line-height: 26px;
  }

  .our-goods-text {
    padding: 24px 0 15px 0;
    max-width: 265px;
  }
  .our-goods-text .main-btn {
    max-width: 235px;
    min-height: 51px;
    margin: 36px 0 0 0;
  }
  .goods-advantage-item h4 {
    font-size: 16px;
    line-height: normal;
  }
  .goods-advantage-item p {
    padding: 8px 0 0 0;
    font-size: 14px;
    line-height: 22px;
  }
  .goods-advantage-item {
    padding: 16px 0 39px 52px;
  }
  .goods-section-title h3 {
    font-size: 30px;
  }
  .goods-section-title p {
    padding: 20px 0 0 0;
    max-width: 385px;
    font-size: 17px;
    line-height: normal;
  }

  .goods-card-container {
    padding: 48px 0;
  }
  .goods-card {
    padding: 31px 0;
  }
  .goods-img {
    height: 434px;
  }

  .goods-item p {
    font-size: 17px;
  }
  .goods-item h4 {
    font-size: 27px;
    line-height: normal;
  }
  .goods-description p {
    font-size: 12px;
    line-height: 20px;
  }
  .goods-description ol li {
    padding: 11px 0 11px 29px;
    font-size: 12px;
  }
  .goods-description ol {
    padding: 17px 0 11px 0;
  }
  .goods-description ol li:before {
    width: 45px;
    height: 43px;
    top: -6px;
  }

  .goods-notice {
    width: calc(100% - 161px);
  }
  .goods-order .main-btn {
    max-width: 161px;
    min-height: 36px;
    font-size: 9px;
  }
  .goods-notice p:before {
    width: 25px;
    height: 25px;
    //top: 4px;
  }
  .goods-notice {
    min-height: 25px;
  }
  .goods-notice p {
    padding: 0 10px 0 37px;
    font-size: 12px;
    line-height: normal;
  }
  .offer-wrap {
    padding: 60px 0;
  }
  .offer-data {
    max-width: 490px;
  }
  .offer-title p {
    font-size: 17px;
  }
  .offer-title h3 {
    font-size: 27px;
    line-height: 36px;
  }
  .offer-text p {
    font-size: 14px;
  }
  .offer-text {
    padding: 30px 0 0 0;
  }
  .offer-text ol li:before {
    width: 45px;
    height: 43px;
    top: -6px;
  }
  .offer-text ol li {
    padding: 12px 0 12px 31px;
    font-size: 11px;
  }
  .offer-text ol {
    padding: 16px 0;
  }
  .offer-discount p {
    font-size: 18px;
  }
  .offer-discount p b {
    font-size: 24px;
  }
  .quiz-page {
    padding-top: 84px;
  }
  .quiz-title p {
    padding: 0 0 10px 0;
    font-size: 17px;
  }
  .quiz-title h2 {
    font-size: 29px;
    line-height: normal;
  }
  .quiz-wrap {
    padding: 67px 0 93px 0;
  }
  .quiz-title {
    padding: 0 0 55px 0;
  }
  .quiz-container:after {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
  .quiz-container {
    padding: 23px;
  }
  .quiz-question {
    padding: 13px;
  }
  .question-text p {
    font-size: 10px;
  }
  .question-text h3 {
    font-size: 22px;
  }
  .question-text {
    padding: 0 0 27px 0;
  }
  .place-img {
    height: 138px;
  }
  .place-option label span:before,
  .feature-option label span:before {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
  .place-option label {
    margin: 15px 0 0 0;
    padding: 6px 35px;
  }
  .place-option label span,
  .feature-option label span {
    font-size: 16px;
  }
  .place-option label span:after {
    left: 35px;
    right: 35px;
  }
  .use-option label span {
    font-size: 16px;
    line-height: 18px;
    min-height: 68px;
  }
  .continue-quiz {
    padding: 24px 0 16px 0;
  }
  .continue-quiz .main-btn {
    max-width: 200px;
    min-height: 43px;
  }
  .our-works {
    padding: 66px 0;
  }
  .our-works-title p {
    padding: 0 0 10px 0;
    font-size: 17px;
  }
  .our-works-title h2 {
    font-size: 29px;
  }
  .our-works-wrap {
    padding: 34px 0;
  }
  .our-works-info {
    padding: 35px 29px;
    height: 227px;
  }
  .our-works-main h4 {
    font-size: 12px;
    line-height: 20px;
  }
  .our-works-main p {
    padding: 11px 7px 5px 0;
    font-size: 11px;
    line-height: 17px;
  }
  .our-works-about a {
    font-size: 10px;
  }
  .more-our-works p {
    font-size: 16px;
  }
  .experience {
    padding: 69px 0 77px 0;
  }
  .experience-item-wrap {
    padding: 17px 28px;
    min-height: 179px;
  }
  .experience-advantages-icon p {
    font-size: 12px;
  }
  .experience-advantages-icon span {
    width: 68px;
    height: 88px;
  }
  .experience-advantages-icon {
    width: 68px;
  }
  .experience-advantages-text {
    width: calc(100% - 68px);
    padding: 14px 0 0 26px;
  }
  .experience-advantages-icon p:nth-child(2) {
    padding-top: 5px;
  }
  .experience-advantages-text h3 {
    font-size: 13px;
    line-height: 22px;
  }
  .experience-advantages-text h4 {
    font-size: 17px;
    line-height: 24px;
  }
  .experience-advantages-text p {
    padding: 14px 0 0 0;
    font-size: 13px;
    line-height: 22px;
  }
  .experience-item:after {
    right: 21px;
    left: 21px;
  }
  .experience-item {
    margin: 0 0 33px 0;
  }
  .more-goods {
    padding: 61px 0;
  }
  .more-goods-items {
    padding: 33px 0 0 0;
  }
  .more-goods-bg {
    height: 133px;
    padding: 31px;
  }
  .more-goods-bg h3 {
    padding: 0 0 10px 0;
    font-size: 26px;
  }
  .more-goods-bg a {
    font-size: 10px;
  }
  .more-goods-bg a span {
    padding: 0 0 1px 0;
  }
  .more-goods-bg a i {
    margin: 0px 0 0 6px;
    font-size: 12px;
  }

  footer {
    padding: 55px 0;
  }
  .footer-logo a {
    width: 128px;
  }
  .footer-logo p {
    width: calc(100% - 128px);
    padding: 0 0 5px 31px;
    font-size: 10px;
    line-height: 15px;
  }
  .main-tel a {
    padding: 3px 0;
    font-size: 11px;
  }
  .tel-platform i {
    margin: 0 4px 0 0;
    font-size: 14px;
  }
  .tel-platform {
    padding: 0 4px 0 0;
  }
  .join-us {
    min-width: 37%;
  }
  .join-us p {
    font-size: 11px;
  }
  .join-us p span {
    padding: 0 4px 0 0;
  }
  .footer-copyright {
    padding: 36px 0 0 0;
  }
  .footer-rights p {
    font-size: 11px;
  }
  .made-by p {
    font-size: 11px;
  }
  .project-title h2 {
    font-size: 30px;
  }
  .project-title p {
    padding: 13px 0 0 0;
    font-size: 18px;
  }
  .project-title-wrap {
    min-height: 239px;
  }
  .project-time h3 {
    font-size: 30px;
  }
  .project-time h3:before {
    width: 38px;
    //top: 18px;
    top: 17px;
    left: -78px;
  }
  .made-work ol li {
    font-size: 14px;
    line-height: 22px;
  }
  .made-work ol li span:before {
    font-size: 23px;
  }
  .reviews-video {
    padding: 28px 0 0 0;
  }
  .project-section-title h2 {
    font-size: 30px;
    line-height: normal;
  }
  .project-section-title p {
    padding: 0 0 8px 0;
    font-size: 17px;
  }
  .project-review-wrap {
    padding: 2px 0;
  }
  .review-item-bg {
    height: 290px;
  }
  .project-review-item {
    padding: 15px 15px 0 15px;
    width: 100%;
  }
  .reviews-video.client-video .review-item-bg {
    height: 332px;
  }
  .project-video-icon a {
    width: 45px;
    height: 45px;
  }
  .project-video-icon a i {
    padding: 1px 3px 0 5px;
    font-size: 15px;
  }
  .project-contact-bg {
    padding: 68px 0;
  }
  .project-contact-title h3 {
    padding: 0 0 13px 0;
    font-size: 22px;
  }
  .project-contact-title p {
    font-size: 16px;
    padding: 7px 0 0 0;
  }
  .form-fields {
    padding: 25px 0;
  }
  .form-fields label {
    max-width: 245px;
    padding: 0 17px 0 0;
  }
  .form-fields label input {
    padding: 14px 28px 14px 41px;
    font-size: 15px;
  }
  .form-fields label i {
    top: 20px;
    left: 21px;
  }
  .form-fields .main-btn {
    max-width: 202px;
    min-height: 44px;
  }
  .form-agreement label span {
    font-size: 12px;
  }
  .form-agreement label span:before {
    padding: 1px 0 0 1px;
    font-size: 9px;
    width: 19px;
    height: 19px;

  }
  .main-modal.iziModal {
    max-width: 915px !important;
  }
  .main-modal-title h3 {
    padding: 0 0 10px 0;
    font-size: 25px;
    line-height: 30px;
  }
  .main-modal-title p {
    padding: 0 0 23px 0;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1140px) {
  header .container,
  .container {
    max-width: 970px;
  }
  .extra-wrap {
    //padding: 0 52px;
    padding: 0 83px;
  }
  .main-title h1:before,
  .our-goods-title h2:before,
  .project-time h3:before,
  .experience-title h2:before {
    width: 37px;
    //top: 18px;
    left: -51px;
  }
  .footer-logo p {
    padding: 0 0 5px 25px;
  }
}

@media screen and (max-width: 990px) {
  .extra-wrap {
    padding: 0;
  }
  .main-title h1:before,
  .our-goods-title h2:before,
  .project-time h3:before,
  .experience-title h2:before {
    display: none;
  }
  .quiz-title h2 {
    margin: 0;
  }
  .goods-data {
    padding: 0 0 0 6%;
  }
  .footer-logo p {
    padding: 0 0 5px 14px;
  }
  .footer-logo {
    width: 281px;
  }
  .footer-logo p {
    padding: 0 0 5px 14px;
    line-height: normal;
  }
}

@media screen and (max-width: 850px) {
  .our-goods-info {
    width: 100%;
  }
  .our-goods-advantages {
    width: 100%;
  }
  .our-goods-text {
    max-width: 100%;
  }
  .our-goods-title p,
  .experience-title p {
    text-align: center;
  }
  .our-goods-title h2,
  .experience-title h2,
  .more-goods-title h2,
  .project-time h3 {
    text-align: center;
  }
  .goods-advantage-item {
    padding: 16px 0 16px 52px;
  }
  .experience-info {
    width: 100%;
    padding-right: 0;
  }
  .experience-text {
    max-width: 100%;
  }
  .experience-advantages {
    width: 100%;
  }
  .experience-item-wrap {
    min-height: 160px;
  }
  .experience-item {
    margin: 0 0 25px 0;
  }

  .header-wrap {
    justify-content: space-between;
    &:after {
      content: '';
      clip-path: inset(100% 0 0 0);
      position: absolute;
      top: -16px;
      left: -15px;
      right: -15px;
      bottom: -1px;
      background: black;
      z-index: -1;
      transition: .3s;
    }
    &.active {
       &:after {
         clip-path: inset(0 0 0 0);
       }
     }
  }
  .header-data {
    width: calc(100% + 30px);
  }
  .menu-btn {
    display: block;
  }
  .header-data {
    padding: 0 15px 0 15px;
    clip-path: inset(0 0 100% 0);
    overflow: hidden;
    //flex-wrap: wrap;
    flex-direction: column;

    position: absolute;
    background: #000000;
    left: -15px;
    right: -15px;
    top: 100%;
    z-index: -1;
    transition: .3s;
    &:not(.active) {
      pointer-events: none;
    }
    &.active {
      clip-path: inset(0 0 0 0);
    }
  }
  .main-menu {
    padding: 10px 0 20px 0;
    //width: 100%;
  }
  .header-contacts {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 20px 0;
  }
  .change-lang {
    width: 100%;
    padding: 10px 0 20px 0;
    justify-content: center;
  }
  .main-content,
  .offer-wrap {
    background-position: center;
  }
  .project-time {
    width: 100%;
    padding: 11px 0 20px 0;
  }
  .made-work {
    width: 100%;
  }
  .project-data {
    padding: 50px 0;
  }
  .reviews-video {
    padding: 0;
  }
  .reviews-video:not(.client-video) .project-review-item:nth-child(4n + 1),
  .reviews-video:not(.client-video) .project-review-item:nth-child(4n + 2),
  .reviews-video:not(.client-video) .project-review-item:nth-child(4n + 3),
  .reviews-video:not(.client-video) .project-review-item:nth-child(4n + 4) {
    width: 100%;
  }
  .quiz-page {
    padding-top: 78px;
  }
}

@media screen and (max-width: 790px) {
  .goods-img {
    width: 100%;
    height: 300px;
  }
  .goods-data {
    padding: 30px 0 0 0;
    width: 100%;
  }
  /*.goods-notice p:before {
    top: 0;
  }*/
  .question-option {
    padding: 0;
  }
  .place-option,
  .use-option {
    width: calc(100% / 2);
  }
  .feature-option {
    padding: 0 15px 0 0;
  }
  .our-works-info {
    padding: 20px 20px;
  }
  .our-works-item {
    width: calc(100% / 2);
  }
  .close-project-modal {
    padding: 0 0 50px 0;
  }


  .not-found-wrap {
    padding: 97px 0 20px 0;
    min-height: calc(100vh - 305px);
  }
  .not-found-text h1 {
    font-size: 175px;
  }
  .not-found-text p {
    font-size: 20px;
  }
}

@media screen and (max-width: 690px) {
  .more-goods-wrap {
    width: 100%;
  }
  .place-option {
   margin: 0 auto;
  }
  .place-option, .use-option {
    width: 100%;
  }
  .feature-option {
    width: 100%;
    padding: 0;
  }
  .modal-wrap {
    padding: 40px 40px;
  }
  .close-btn {
    font-size: 27px;
    top: 15px;
    right: 14px;
  }
}

@media screen and (max-width: 550px) {
  .main-title p {
    font-size: 16px;
  }
  .main-title h1,
  .our-goods-title h2,
  .experience-title h2,
  .more-goods-title h2,
  .project-time h3,
  .project-section-title h2 {
    font-size: 24px;
    line-height: normal;
  }
  .main-title h1 span {
    font-size: 14px;
  }
  .main-advantages ol li {
    padding: 11px 14px 12px 25px;
  }
  .main-content-btn p {
    padding: 0 0 15px 0;
    font-size: 14px;
  }
  header {
    padding: 0;
  }
  .header-wrap {
    padding: 10px 0;
  }
  .main-logo a {
    margin-top: 0;
  }
  .main-content {
    padding: 86px 0 25px 0;
  }
  .our-goods-wrap {
    padding: 30px 0 45px 0;
  }
  .our-goods-text {
    padding: 15px 0;
  }
  .our-goods-text .main-btn {
    margin: 25px 0 0 0;
  }
  .our-goods-text p, .experience-text p {
    font-size: 14px;
    line-height: 22px;
  }
  .goods-card-container {
    padding: 0;
  }
  .goods-notice {
    width: 100%;
    padding: 0 0 15px 0;
  }
  .goods-item h4 {
    font-size: 23px;
  }
  .offer-wrap {
    padding: 40px 0;
  }
  .quiz-title {
    padding: 0 0 35px 0;
  }
  .quiz-question {
    padding: 13px 0;
  }
  .question-text {
    padding: 0 0 10px 0;
  }
  .use-option {
    padding: 15px 0;
  }
  .our-works-item {
    width: 100%;
  }
  .our-works {
    padding: 40px 0;
  }
  .our-works-wrap {
    padding: 20px 0;
  }
  .more-our-works p {
    font-size: 14px;
  }
  .more-our-works p a {
    font-size: 14px;
  }
  .experience {
    padding: 40px 0;
  }
  .experience-item-wrap {
    padding: 15px;
  }
  .experience-advantages-text {
    padding: 10px 0 0 10px;
  }
  .more-goods {
    padding: 40px 0;
  }
  .join-us {
    width: 100%;
    padding: 9px 0 15px 0;
  }
  .more-goods-items {
    padding: 20px 0 0 0;
  }







  .offer-title h3 {
    font-size: 23px;
    line-height: normal;
  }
  .goods-section-title h3 {
    font-size: 24px;
  }
  .quiz-title h2 {
    font-size: 24px;
  }
  .quiz-title p {
    font-size: 16px;
  }

  .project-title h2 {
    font-size: 24px;
  }
  .project-title p {
    font-size: 14px;
  }
  .share-project p {
    font-size: 13px;
  }
  .share-project p span {
    padding: 0 0 3px 0;
    display: block;
  }
  .made-work ol li {
    width: 100%;
    padding: 8px 0;
    order: unset !important;
  }
  .made-work ol li span {

    //min-height: 22px;
    padding: 0 0 0 32px;
  }
  /*.made-work ol li span:before {
    top: 0;
  }*/
  .project-section-title {
    padding: 30px 0 15px 0;
  }
  .project-contact-wrap {
    padding: 0;
  }
  .form-fields {
    flex-wrap: wrap;
  }
  .form-fields label {
    max-width: 100%;
    padding: 0 0 15px 0;
  }
  .form-fields .main-btn {
    max-width: 100%;
    min-height: 44px;
  }
  .close-project-modal {
    padding: 0 0 20px 0;
  }
  .made-work ol li span {
    max-width: 100%;
  }
  .modal-wrap {
    padding: 40px 15px;
  }
  .not-found-wrap {
    min-height: calc(100vh - 330px);
  }
  .not-found-text h1 {
    font-size: 140px;
  }
  .not-found-text p {
    font-size: 16px;
  }
}
